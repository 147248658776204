.map-container {
  height: 100vh;
}

.columns {
  margin: 0;
}

.column {
  padding: 0;
}
